
"use client";
import React from "react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

export default function ClientSlider() {
  return (
    <>
      <div className="bg-white relative">
        <div className="mx-auto px-4 sm:px-6 lg:px-16 py-8">
          <h1 className="text-center font-extrabold text-2xl sm:text-4xl lg:text-5xl text-gray-700 mb-4">
            Your Money is 100% Secure!
          </h1>

          <p className="text-center text-base sm:text-lg lg:text-xl text-gray-700 mb-8">
            Your asset is stored in secured vaults at Bright Digi Gold and is
            available for withdrawal anytime.
          </p>

          <div className="flex  sm:grid sm:grid-cols-3 sm:gap-0 items-center">
            <div className="relative flex flex-col items-center mx-4 sm:mx-0">
              <img
                src="/bright_gold.png"
                className="w-14 sm:w-28 lg:w-28 mb-2"
                alt="Client 1"
              />
              <span className="text-center text-gray-700 text-sm sm:text-base">
                Gold Refinery
              </span>
              <div className="hidden sm:block absolute right-[-1px] top-0 bottom-0 w-[1px] bg-gray-300"></div>
            </div>

            <div className="relative flex flex-col items-center mx-4 sm:mx-0">
              <img
                src="/client1.png"
                className="w-20 sm:w-28 lg:w-40 mb-2"
                alt="Client 2"
              />
              <span className="text-center text-gray-700 text-sm sm:text-base">
                NABL Certified
              </span>
              <div className="hidden sm:block absolute right-[-1px] top-0 bottom-0 w-[1px] bg-gray-300"></div>
            </div>

            <div className="relative flex flex-col items-center mx-4 sm:mx-0">
              <img
                src="/client8.png"
                className="w-20 sm:w-28 lg:w-40 mb-2"
                alt="Client 3"
              />
              <span className="text-center text-gray-700 text-sm sm:text-base">
                Payment Partner
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
